<script>
import Loader from "vue-element-loading";
import api from "@/api";

export default {
  name: "UserBookings",
  components: {
    Loader,
  },
  data: function () {
    return {
      user_bookings: [],
      loading: false,
    };
  },
  methods: {
    isNonRepetitiveName: function (space) {
      var name1 = space.name;
      var name2 = "";
      if (space.space_company !== undefined) {
        name2 = space.space_company.company_name;
      }
      if (name1 === name2) {
        return false;
      }
      return true;
    },
    getUserBookings() {
      this.loading = true;
      this.user_bookings.splice(0);
      var url = "/schedules/for_user/";
      if (this.$route.params.ID) {
        url = `/schedules/${this.$route.params.ID}/?all=1`;
      }
      api.Util.axios
        .get(url)
        .then((response) => {
          this.loading = false;
          const data = response.data;
          if (data.id) {
            this.user_bookings.push(data);
          } else {
            this.user_bookings = data;
          }
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    cancel_booking(id) {
      this.$store.dispatch("General/updateLoading", 1);
      const url = "/cancelBooking/" + id;
      api.Util.axios.delete(url).then((response) => {
        this.$store.dispatch("General/updateLoading", false);
        if (response.data.cancelled === 1) {
          this.getUserBookings();
        } else {
          alert(response.data.error);
        }
      });
    },
    getDates(datetime1, datetime2) {
      var date1 = datetime1.split("T")[0];
      var date2 = datetime2.split("T")[0];
      if (date1 === date2) {
        return date1;
      } else {
        return date1 + " - " + date2;
      }
    },
    getTime(time) {
      var t = time.split("T")[1];
      var parts = t.split(":");
      return parts[0] + ":" + parts[1];
    },
  },
  created: function () {
    this.getUserBookings();
  },
};
</script>

<template>
  <div id="UserBookings" class="container">
    <Loader :active="true" v-if="loading" />
    <template v-else>
      <template v-if="user_bookings.length">
        <div
          class="row justify-content-center"
          v-for="booking in user_bookings"
          :key="booking.id"
        >
          <div class="col-11 col-md-12 booking">
            <div class="row mb-1">
              <div class="col-md col">
                <template v-if="booking.cancelled">
                  <div class="text-danger fw-bold">
                    <i class="fas fa-exclamation-triangle"></i>
                    CANCELLED
                  </div>
                </template>
                <template v-else-if="booking.active">
                  <div class="text-success fw-bold">
                    <i class="fas fa-check-double"></i>
                    BOOKING APPROVED
                  </div>
                </template>
                <template v-else>
                  <div class="text-secondary fw-bold">
                    <i class="fas fa-exclamation-triangle"></i> NOT APPROVED.
                  </div>
                </template>
              </div>

              <div class="col-md-3 col-12 mb-1">
                <div class="bold">
                  <router-link
                    :to="{
                      name: 'Space-Sections',
                      params: { slug: booking.space_location.slug },
                    }"
                  >
                    {{ booking.space_location.space_company.company_name }}
                    <template
                      v-if="isNonRepetitiveName(booking.space_location)"
                    >
                      - {{ booking.space_location.name }}
                    </template>
                  </router-link>
                </div>
                <div class="d-block grey">
                  {{ booking.space_location.location }}
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="row">
                  <div class="col-md mb-1 col-12">
                    <div class="col-12">
                      <template v-if="booking.title">
                        <strong>Title: </strong>
                        <span v-if="booking.title">{{ booking.title }}</span>
                      </template>
                      <template v-if="booking.group">
                        <strong>Group: </strong>
                        <span v-if="booking.group">{{
                          booking.group.name
                        }}</span>
                      </template>
                    </div>
                  </div>

                  <div class="col-md col-12">
                    <div class="mb-1 col-12">
                      <strong>Date:</strong> {{ booking.date_readable }}
                    </div>
                    <div class="mb-1 col-12">
                      <strong>Hours:</strong>
                      {{ getTime(booking.start_time) }} -
                      {{ getTime(booking.end_time) }}
                    </div>

                    <div class="mb-1 col-12">
                      <span class="xs-only bold">Total:</span>
                      {{ booking.requested_amount }}/=
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-md-4 col-12"
                v-if="booking.is_past === 0 && booking.can_cancel"
              >
                <button
                  class="btn btn-danger"
                  :data="booking"
                  :disabled="booking.can_cancel === 0"
                  @click="cancel_booking(booking.id)"
                >
                  Cancel
                </button>
                <div class="text-grey" v-if="!booking.can_cancel">
                  Cannot cancel rehearsal.
                  <template v-if="booking.is_past === 0 && booking.active">
                    Full payment should be made.
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row justify-content-center">
          <div class="col-12">
            <h3 class="text-muted text-center">So much empty!</h3>
          </div>
          <div class="col-8 col-md-6 no-booking">
            You/your group does not have any bookings.
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<style lang="scss">
#UserBookings {
  padding-top: 10px;
  .booking {
    background: white;
    border: 1px solid #ccc;
    margin-bottom: 5px;
    padding: 10px 10px;
  }
  .grey {
    color: #666;
  }
  .bold {
    font-weight: bold;
  }

  .no-booking {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px;
  }
  h3 {
    margin-bottom: 12px;
  }
}
</style>
